
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ElButton, ElForm, ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Settings",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Тохиргоо");
    });
    const saveSettingsForm = ref<InstanceType<typeof ElForm>>();
    const submitButton = ref<InstanceType<typeof ElButton>>();
    const submitHideButton = ref<InstanceType<typeof ElButton>>();

    const formSubmit = () => {
      saveSettingsForm.value?.validate(async valid => {
        if (valid) submitHideButton.value?.$el.click();
        else return;
      });
    };

    return {
      saveSettingsForm,
      submitButton,
      submitHideButton,
      formSubmit
    };
  },
  mounted() {
    this.getSettings();
  },
  data() {
    return {
      settingsLoading: false,
      saveButtonLoader: false,
      settingsForm: {
        maxActiveTime: "",
        maxCount: "",
        InActiveMin: "",
        headCount: false
      },
      settingsFormRule: {
        maxActiveTime: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        maxCount: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        InActiveMin: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    async saveSettings() {
      if (this.settingsForm.maxCount.length == 0) return;
      if (this.settingsForm.maxActiveTime.length == 0) return;
      if (this.settingsForm.InActiveMin.length == 0) return;
      this.saveButtonLoader = true;
      await ApiService.post(`settings`, {
        params: {
          maxCount: this.settingsForm.maxCount,
          maxActiveTime: this.settingsForm.maxActiveTime,
          InActiveMin: this.settingsForm.InActiveMin,
          headCount: this.settingsForm.headCount ? "1" : "0"
        }
      })
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            }).then(() => {
              this.getSettings();
            });
          }
          this.saveButtonLoader = false;
        })
        .catch(() => {
          this.saveButtonLoader = false;
          Swal.fire({
            text: "Тохиргоо засахад алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },
    async getSettings() {
      this.settingsLoading = true;
      this.saveButtonLoader = true;
      await ApiService.get(`settings`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            results.response.forEach(i => {
              if (i.name === "maxActiveTime")
                this.settingsForm.maxActiveTime = i.value;
              if (i.name === "headCount") this.settingsForm.headCount = i.value;
              if (i.name === "InActiveMin")
                this.settingsForm.InActiveMin = i.value;
              if (i.name === "maxCount") this.settingsForm.maxCount = i.value;
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.settingsLoading = false;
          this.saveButtonLoader = false;
        });
    }
  }
});
